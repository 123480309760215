<template>
  <section class="login-bg">
    <vue-particles color="#fff" :particleOpacity="0.7" :particlesNumber="50" shapeType="circle" :particleSize="3"
      linesColor="#fff" :linesWidth="2" :lineLinked="true" :lineOpacity="0.5" :linesDistance="150" :moveSpeed="3"
      :hoverEffect="true" hoverMode="grab" :clickEffect="true" clickMode="push">
    </vue-particles>

    <div class="login-container">
      <h3 class="title">
        <span>运营管理系统</span>
      </h3>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="login">
        <el-form-item prop="account">
          <el-input size="large" placeholder="请输入您的手机账号" prefix-icon="el-icon-user" @click.native="active(1)"
            @blur="blur(1)" :class="{ active: isActive.a1 }" v-model="ruleForm.account" type="tel"
            :maxlength="11"></el-input>
        </el-form-item>
        <el-form-item prop="checkPass">
          <el-input size="large" placeholder="请输入您的登录密码" prefix-icon="el-icon-password" @click.native="active(2)"
            @blur="blur(2)" :class="{ active: isActive.a2 }" v-model="ruleForm.checkPass" type="password"
            :maxlength="16"></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <div class="flex">
            <div class="w1">
              <el-input class="mw2" size="large" placeholder="请输入验证码" prefix-icon="el-icon-password"
                @click.native="active(3)" @blur="blur(3)" :class="{ active: isActive.a3 }" v-model="ruleForm.code"
                :maxlength="6"></el-input>
            </div>
            <span v-if="ruleForm.account" class="col-b" :class="hideTime ? 'point' : ''" @click="getCode">{{ hideTime ?
              codeStr : countdown + 'S后重新获取' }}</span>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="loginSub" @click.native.prevent="handleSubmit" :loading="logining">登
            录</el-button>
        </el-form-item>
      </el-form>
    </div>
    </div>
  </section>
</template>

<script>

let timeClock = undefined, _t = undefined;
import { newRouter } from './../router/routes'
import { requestLogin, send } from '../api/api';

export default {
  data() {
    return {
      codeStr: '获取手机验证码',
      logining: false,
      isActive: {
        a1: false,
        a2: false,
        a3: false
      },
      ruleForm: { //登录表单
        account: '',
        checkPass: '',
        code: ''
      },
      hideTime: true,
      countdown: 60,
      rules: {  //表单验证
        account: [
          { required: true, message: '手机账号必须填写', trigger: 'blur' },
          { min: 11, max: 11, message: '需为11位号码', trigger: 'blur' }
          //{ validator: validaePass }
        ],
        checkPass: [
          { required: true, message: '密码必须填写', trigger: 'blur' },
          { min: 6, max: 15, message: '长度在6到15个字符', trigger: 'blur' }
        ],
        code: [
          { required: false, message: '请输入验证码', trigger: 'blur' }
        ]
      },
    };
  },
  methods: {
    getCode() {
      if (!this.ruleForm.account) return this.$message.error('手机号不能为空！');
      const params = {
        mobile: this.ruleForm.account,
        event: 'login',
        type: 1
      }
      send(params).then(res => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          this.hideTime = false
          timeClock = setInterval(function () {
            _t.countdown--
            if (_t.countdown <= 0) {
              clearInterval(timeClock);
              _t.countdown = 60
              _t.codeStr = '重新获取验证码'
              _t.hideTime = true
            }
          }, 1000)
        } else {
          this.$message.error(res.msg);
        }
      })

    },
    //获取焦点判断
    active: function (a) {
      switch (a) {
        case 1:
          this.isActive.a1 = true;
          break;
        case 2:
          this.isActive.a2 = true;
          break;
        case 3:
          this.isActive.a3 = true;
          break;
      }
    },
    //失去焦点判断
    blur(a) {
      switch (a) {
        case 1:
          this.isActive.a1 = false;
          break;
        case 2:
          this.isActive.a2 = false;
          break;
        case 3:
          this.isActive.a3 = false;
          break;
      }
    },
    //提交登录
    handleSubmit(ev) {
      var _this = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.logining = true;
          var loginParams = { user_name: this.ruleForm.account, password: this.ruleForm.checkPass, captcha: this.ruleForm.code };
          requestLogin(loginParams).then(d => {
            this.logining = false;
            let { msg, code, data } = d;
            if (code == 1) {
              localStorage.setItem('user', JSON.stringify(data));
              localStorage.setItem('menu', JSON.stringify(data.menu));
              console.log(data.menu)
              this.$router.push({ name: 'main' });
            } else if (code == -2) {
              this.$message({
                type: 'warning',
                message: msg
              });
              this.$prompt('请输入六位数动态码', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(({ value }) => {
                loginParams.getCode = value;
                requestLogin(loginParams).then(d => {
                  let { msg, code, data } = d;
                  if (code == 1) {
                    this.$message({
                      type: 'success',
                      message: msg
                    });
                    localStorage.setItem('user', JSON.stringify(data));
                    localStorage.setItem('menu', JSON.stringify(data.menu));
                    console.log(data.menu)
                    this.$router.push({ name: 'main' });
                  } else {
                    this.$message({
                      message: msg,
                      type: 'error'
                    });
                  }
                });
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '请输入后再试'
                });
              });
            } else {
              this.$message({
                message: msg,
                type: 'error'
              });
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    _t = this
  },
  beforeDestroy() {
    clearInterval(timeClock);
  }
}
</script>

<style lang="scss">
@import "../styles/login.scss";

.flex {
  display: flex;
}

.w1 {
  width: 180px;
}

.col-b {
  color: #ffffff;
}

.mw2 {
  width: 172px;

  input {
    width: 100% !important;
  }
}

.point {
  cursor: pointer;
}
</style>